import type { CompanySizeOptionsObject } from "../../translation-keys/company-size-option-keys.js";

const FormCompanySizeOptions: CompanySizeOptionsObject = {
  "1 (GT 10,000 emp)": "직원 수 +10,000명",
  "2 (5,000-10,000 emp)": "직원 수 5,000~9,999명",
  "3 (1,000-5,000 emp)": "직원 수 1,000~4,999명",
  "4 (500-1,000 emp)": "직원 수 500~999명",
  "5 (100-500 emp)": "직원 수 100~499명",
  "6 (50-100 emp)": "직원 수 50~99명",
  "7 (50-25 emp)": "직원 수 25~49명",
  "8 (LT 25 emp)": "직원 수 0~24명",
};

export default FormCompanySizeOptions;
